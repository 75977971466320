<script setup>

import Heading2 from '@/Components/Heading2.vue';
import ButtonIcon from '@/Components/ButtonIcon.vue';
import { reactive } from 'vue';

const props = defineProps({ 
    idC: String,            // Id of modal component
    title: String,          // Titel
    fullSize: {             // Indicate if h-full/w-full shall be applied
        fullSize: Boolean,
        default: false,     
    },
    cancelIcon: {
        cancelIcon: Boolean,
        default: true,     
    }
});


const view = reactive({ visible: false, }); 


const show = () => {
    //document.getElementById(props.idC).style.display = "block";
    view.visible = true;
    document.body.style.overflow = "hidden";
}

const hide = () => {
    //document.getElementById(props.idC).style.display = "none";
    view.visible = false;
    document.body.style.overflow = null;

}

defineExpose({
    show,
    hide
})

</script>

<template>
    <teleport to="body">
        <transition leave-active-class="duration-200">

            <div :id="props.idC" :class="view.visible ? 'block':'hidden'" class="fixed overflow-y-auto inset-0 z-50 bg-black dark:bg-white bg-opacity-40 dark:bg-opacity-70 p-4 flex justify-center items-center">

                    <div class="shadow rounded-lg bg-white dark:bg-gray-800 max-w-7xl mx-auto grid grid-rows-[60px_minmax(0,_1fr)_60px]" :class="props.fullSize ? '!h-full !w-full':'!h-fit !w-fit'" >

                            <!-- Header -->
                            <div class="px-4 border rounded-t header-format flex justify-between place-items-center">
                                <Heading2 :text="props.title"/> 
                                <ButtonIcon v-if="cancelIcon" :icon="'close'" @onClick="hide()"/>
                            </div>

                            <!-- Content -->
                            <div class="p-4 border color-border" :class="props.fullSize ? 'h-full':'h-fit'">
                                <slot name="content"/>
                            </div>
                            
                            <!-- Control -->
                            <div class="px-4 border header-format rounded-b flex gap-2 justify-end place-items-center">
                                <slot name="control"/>
                            </div>

                    </div>
            </div>
            
        </transition>
    </teleport>
</template>