<script setup>
import { reactive, ref, onMounted } from 'vue';
import { Head, router, usePage } from '@inertiajs/vue3';
import ButtonNav from '@/Components/ButtonNav.vue';
import ButtonStd from '@/Components/ButtonStd.vue';
import ButtonIcon from '@/Components/ButtonIcon.vue';
import RadioItem from '@/Components/RadioItem.vue';
import Modal from '@/Layouts/Modal.vue';
import xhr from '@/Lib/xhr.js';
import Search from '@/Components/Search.vue';

const props = defineProps({
    title: String,
    notifications: {
        notifications: Array,
        default: [],
    },
    search: {
        search: Boolean,
        default: true,         // indicate if search shall be available
    },
    navBar: {
        navBar: Boolean,
        default: true,        // indicate if navigation bar shall be visivle
    },
});


onMounted(() => { 
    setMode();
    //console.log(usePage().props);
});

/**
 * Toggle between light and dark mode
 */
 const toggleMode = () => {
    if (state.mode == "light") {
        setMode("dark");
    } else {
        setMode("light");
    }
}

/**
 * Set light or dark mode
 * @param {string} mode undefined = use definition in local storage, light or dark
 */
const setMode = (mode) => {
    if (mode == undefined) {
        state.mode = localStorage.getItem('mode');
    } else {
        state.mode = mode;
    }

    if (state.mode == "light") {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    } else {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
    }

    localStorage.setItem("mode", state.mode);
}

/**
 * Redirect to explore view
 */
const search = () => {
    window.location.href = "/set/explore/" + encodeURIComponent(state.searchText);
}

/**
 * Reactive parameters
 */
const state = reactive({
  menu: false,             // state of navigation menu
  mode: "light",           // moe light or dark
  searchText: "",          // search text
  notificationView: false, // true = show view with notifications
});

/**
 * Open dialog to select item type to create
 */
const openCreationDialog = () => { 
    creationDialog.value.show();
}
const creationDialog = ref();

/**
 * Create selected item type and go to edit page
 */
const create = () => { 
    let route_path = document.querySelector('input[name="create"]:checked').value;
    window.location.href= route_path;
}

/**
 * Action of notifications
 * @param {String} action = confirm or reject        
 * @param {Object} notification Notification object
 */
const notifyAction = (action, notification) => {
    //console.log(action, notification);

    xhr.get("/notification/answer", "POST", null);
    xhr.send( { id: notification.id, answer: action } );

    // Delete in frontend...
    props.notifications.splice(props.notifications.findIndex(n => n.id == notification.id), 1); 
}

</script>

<template>
    <div>
        <Head :title="$t(props.title)" />

        <div class="min-h-screen bg-gray-50 dark:bg-gray-900">

            <!-- Live search -->
            <Search v-if="props.search" v-model="state.searchText" :placeholder="'set.explore.search'" :class="'!fixed top-2 left-4 w-[calc(100vw-128px)] sm:w-96 z-40'" @search="search"/>

            <!-- Dark / light mode icon -->
            <div @click="toggleMode()" class="fixed top-2 right-4 flex items-center justify-center z-30 w-10 h-10 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-600 rounded-full hover:bg-gray-50 dark:hover:bg-gray-700 group">
                <svg v-if="state.mode = 'dark'" class="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 fill-current" viewBox="0 0 24 24" >
                    <path d="M12 6a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm9 5h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2ZM6 12a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm.22-7a1 1 0 0 0-1.39 1.47l1.44 1.39a1 1 0 0 0 .73.28 1 1 0 0 0 .72-.31 1 1 0 0 0 0-1.41ZM17 8.14a1 1 0 0 0 .69-.28l1.44-1.39A1 1 0 0 0 17.78 5l-1.44 1.42a1 1 0 0 0 0 1.41 1 1 0 0 0 .66.31ZM12 18a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Zm5.73-1.86a1 1 0 0 0-1.39 1.44L17.78 19a1 1 0 0 0 .69.28 1 1 0 0 0 .72-.3 1 1 0 0 0 0-1.42Zm-11.46 0-1.44 1.39a1 1 0 0 0 0 1.42 1 1 0 0 0 .72.3 1 1 0 0 0 .67-.25l1.44-1.39a1 1 0 0 0-1.39-1.44ZM12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"/>
                </svg>
                <svg v-else class="w-4 h-4 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 fill-current" viewBox="0 0 16 16">
                    <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"/>
                </svg>
            </div>

            <!-- Notifications (show only if notifications are present)  -->
            <div v-if="props.notifications.length > 0" id="notification" @click="state.notificationView = !state.notificationView" class="fixed top-2 right-16 flex items-center justify-center z-30 w-10 h-10 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-600 rounded-full hover:bg-gray-50 dark:hover:bg-gray-700 group">
                <svg id="notification-icon" class="w-4 h-4 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 fill-current" viewBox="0 0 36 36">
                    <path d="M32.51 27.83A14.4 14.4 0 0 1 30 24.9a12.63 12.63 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.63 12.63 0 0 1-1.35 4.81 14.4 14.4 0 0 1-2.47 2.93 1 1 0 0 0-.34.75v1.36a1 1 0 0 0 1 1h27.8a1 1 0 0 0 1-1v-1.36a1 1 0 0 0-.34-.75ZM5.13 28.94a16.17 16.17 0 0 0 2.44-3 14.24 14.24 0 0 0 1.65-5.85v-4.94a8.74 8.74 0 1 1 17.47 0v4.94a14.24 14.24 0 0 0 1.65 5.85 16.17 16.17 0 0 0 2.44 3Z"/>
                    <path d="M18 34.28A2.67 2.67 0 0 0 20.58 32h-5.26A2.67 2.67 0 0 0 18 34.28Z"/>
                </svg>
                <div v-if="props.notifications.length > 0" class="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -end-0 dark:border-gray-900 z-[31]">
                    {{ props.notifications.length > 9 ? "9": props.notifications.length }}
                </div>
            </div>

            <div id="notification-result" class="fixed top-14 right-4 z-30"  v-show="state.notificationView" >
                    <div class="relative overflow-x-auto bg-white dark:bg-gray-900 border rounded-lg border-gray-200 dark:border-gray-600 w-[calc(100vw-32px)] sm:w-96 h-fit">
                        <table class="w-full table-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg">
                            <thead class="">
                                <tr>
                                    <th scope="col" class="p-2"> {{ $t('notification.message') }} </th>
                                    <th scope="col" class="w-24 p-2"> {{ $t('notification.action') }} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-show="props.notifications.length == 0" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <th scope="row" class="p-2"> {{ $t("notification.empty") }} </th>
                                </tr>
                                <tr v-for="notification in props.notifications" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <th scope="row" class="p-2"> {{ notification.action }} </th>
                                    <th v-if="notification.event_type == 1" scope="row" class="p-2 flex justify-end gap-1">
                                        <ButtonIcon :icon="'confirm2'" @onClick="notifyAction('confirm', notification)"/> 
                                        <ButtonIcon :icon="'close'"    @onClick="notifyAction('reject', notification)"/>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>

            <!-- Page heading -->
            <header v-if="$slots.header">
                <div class="max-w-7xl mx-auto pt-14 px-4 sm:px-6 lg:px-8 flex gap-2 items-center justify-start text-gray-700 dark:bg-gray-900">
                    <slot name="header" />
                </div>
            </header>

            <!-- Page content -->
            <main v-if="$slots.header">
                <div class="max-w-7xl mx-auto pt-4 pb-[90px] px-4 sm:px-6 lg:px-8 flex flex-wrap gap-2">
                    <slot />
                </div>
            </main>

            <!-- Nav bar -->
            <div v-if="props.navBar == true && !state.menu" @click="state.menu = !state.menu" class="fixed flex items-center justify-center z-30 w-16 h-16 bg-white dark:bg-gray-300 border border-gray-200 dark:border-gray-600 rounded-full bottom-4 left-1/2 -translate-x-1/2 hover:bg-gray-50 dark:hover:bg-gray-100"  >
                <img class="w-8 h-8" src="/asset/media/logo/logo-s.svg" alt="Logo icon">
            </div>
            <div v-if="props.navBar == true && state.menu" class="fixed flex z-30 w-fit h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 dark:bg-gray-700 dark:border-gray-600">
                <ButtonNav :icon="'navbar.hide'" @onClick="state.menu = !state.menu" class="rounded-s-full" />
                <ButtonNav :icon="'activity'" :route_def="'report.show'"/>
                <ButtonNav :icon="'set.library'" :route_def="'set.library'"/>
                <ButtonNav v-if="usePage().props.auth.user.role == 1" :route_def="'school.show'" :icon="'school'"/>
                <ButtonNav :icon="'auth.profile'" :route_def="'user.profile'"/>
                <ButtonNav :icon="'global.create.new'" @onClick="openCreationDialog()" class="rounded-e-full" />
            </div>

        </div>

        <!-- Select type of creation -->
        <Modal :idC="'creationDialog'" ref="creationDialog" :title="'global.create.new'" :fullSize="false">
            
            <template #content>
                <ul class="grid w-full gap-6 md:grid-cols-2">
                    <RadioItem :name="'create'" :id="'type.flashcard'" :value="'/set/createFlashCard'" :checked="true"/>
                    <RadioItem :name="'create'" :id="'type.testset'"   :value="'/set/createTest'"      :checked="false"/>
                </ul>
            </template>

            <template #control>
                <ButtonStd :active="true" @click="create()" :label="'global.create'"/>
            </template>

        </Modal>
    </div>
</template>